import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import usePrevious from "../../../../../hooks/usePrevious";
import { AppState } from "../../../../../store/RootReducer";
import {
  PAYMENT_METHODS__ROOT,
  PAYMENT__CARD_CARDINAL,
  PAYMENT__CARD_PIN,
} from "../../../../Routes";
import {
  GetBinConfigurationRequest,
  GetBinConfigurationResponse,
  MakePaymentRequest,
  MakePaymentResponse,
} from "../../../domain/repositories/CardRepository";
import { triggerShowNotification } from "../../../../components/NotificationWidget/store/actions";
import { TriggerShowNotificationPayload } from "../../../../components/NotificationWidget/store/types";
import {
  PaymentChannelType,
  PaymentParams,
} from "../../../../payment/presentation/store/paymentProperties/types";
import {
  getIsCardPanValid,
  getIsCardExpiryValid,
  getIsCardCvvValid,
} from "../../../utils/fieldValidators";
import { generateSecureData } from "../../../utils/secureGenerator";
import {
  resetCachedCardDetails,
  setCachedCardDetails,
} from "../../store/cachedCardDetails/actions";
import { CachedCardParams } from "../../store/cachedCardDetails/types";
import {
  resetGetBinConfiguration,
  triggerGetBinConfiguration,
} from "../../store/getBinConfiguration/actions";

import {
  PageView,
  PageTitle,
  PageSubTitle,
  LabelFieldContainer,
  FieldLabel,
  FormFieldsRow,
  FieldErrorText,
  DisclaimerContainer,
} from "../../../../components/Layout";
import { Button } from "../../../../components/Button";
import BackControl from "../../../../components/BackControl";
import CardInputField from "../../../../components/CardInputField";
import ExpiryInputField from "../../../../components/ExpiryInputField";
import CvvInputField from "../../../../components/CvvInputField";
import { getFormattedPrice } from "../../../../../util/currencyUtil";
import {
  resetMakePayment,
  triggerMakePayment,
} from "../../store/makePayment/actions";
import { completeTransaction } from "../../../../payment/presentation/store/paymentStatus/actions";
import { PaymentUtil } from "../../../../payment/util/PaymentUtil";
import { AxiosError } from "axios";
import { getAxiosErrorData } from "../../../../../core/api/helpers";
import getCardinalDeviceInformation from "../../../utils/deviceInformation";
import DisclaimerView from "../../../../components/DisclaimerView";

const Container = styled(PageView)`
  display: flex;
  flex-direction: column;
`;

const Form = styled.form`
  width: 100%;
`;

interface StoreStateProps {
  paymentParams: PaymentParams;
  currentPaymentChannel: PaymentChannelType;
  cachedCardParams: CachedCardParams | null;

  fetchingBinConfiguration: boolean;
  fetchingBinConfigurationError: boolean;
  binConfigurationResponse: GetBinConfigurationResponse;
  fetchingBinConfigurationErrorMessage?: string;

  makePaymentPending: boolean;
  makePaymentError: AxiosError | null;
  makePaymentResponse: MakePaymentResponse | null;
}

interface StoreDispatchProps {
  showNotification: (payload: TriggerShowNotificationPayload) => void;
  setCachedCardDetails: (cardParams: CachedCardParams) => void;
  completeTransaction: (transactionResponse: any) => void;
  getBinConfiguration: (request: GetBinConfigurationRequest) => void;
  makePayment: (request: MakePaymentRequest) => void;
  resetCachedCardDetails: () => void;
  resetGetBinConfiguration: () => void;
  resetMakePayment: () => void;
}

interface OwnProps {}

type Props = StoreStateProps & StoreDispatchProps & OwnProps;

export function CardRootPage(props: Props) {
  const {
    paymentParams,
    currentPaymentChannel,
    cachedCardParams,

    fetchingBinConfiguration,
    fetchingBinConfigurationError,
    binConfigurationResponse,
    fetchingBinConfigurationErrorMessage,

    makePaymentPending,
    makePaymentError,
    makePaymentResponse,

    showNotification,
    setCachedCardDetails,
    completeTransaction,
    getBinConfiguration,
    makePayment,
    resetCachedCardDetails,
    resetGetBinConfiguration,
    resetMakePayment,
  } = props;

  const history = useHistory();

  const prevFetchingBinConfiguration = usePrevious(fetchingBinConfiguration);
  const prevMakePaymentPending = usePrevious(makePaymentPending);

  const [showFormErrors, setShowFormErrors] = useState(false);

  const [cardPanValue, setCardPanValue] = useState(
    cachedCardParams ? cachedCardParams.cardNumber : ""
  );

  const [expValue, setExpValue] = useState(
    cachedCardParams ? cachedCardParams.cardExpiry : ""
  );

  const [cvvValue, setCvvValue] = useState("");

  const goBack = () => {
    resetCachedCardDetails();
    history.goBack();
  };

  const formSubmitHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.nativeEvent.preventDefault();
    event.nativeEvent.stopImmediatePropagation();

    handleSubmit();
  };

  const { isValid: isCardNumberValid, message: cardNumberErrorMessage } =
    getIsCardPanValid(cardPanValue);

  const { isValid: isCardExpValid, message: cardExpErrorMessage } =
    getIsCardExpiryValid(expValue, new Date());

  const { isValid: isCardCvvValid, message: cardCvvErrorMessage } =
    getIsCardCvvValid(cvvValue);

  const handleSubmit = () => {
    setShowFormErrors(true);

    if (!isCardNumberValid || !isCardExpValid || !isCardCvvValid) {
      return;
    }

    const { secure, pinBlock } = generateSecureData(
      cardPanValue,
      expValue,
      cvvValue
    );

    getBinConfiguration({
      secureData: secure,
      pinBlock: pinBlock,
      merchantCode: paymentParams.merchantCode,
      payableCode: paymentParams.payableCode,
      paymentId: paymentParams.paymentId,
    });
  };

  useEffect(() => {
    return () => {
      resetGetBinConfiguration();
      resetMakePayment();
    };
  }, []);

  useEffect(() => {
    if (!(!fetchingBinConfiguration && prevFetchingBinConfiguration)) return;

    if (fetchingBinConfigurationError) {
      showNotification({
        type: "ERROR",
        message: fetchingBinConfigurationErrorMessage,
      });
      return;
    }

    // Cache card details
    setCachedCardDetails({
      cardNumber: cardPanValue,
      cardExpiry: expValue,
      cardCvv: cvvValue,
    });

    const { cardTypeGatewayConfiguration, cardinalAuthenticationInformation } =
      binConfigurationResponse;

    const {
      supportsCardinalAuthentication,
      supportThreeDsAuthentication,
      supportsPin,
    } = cardTypeGatewayConfiguration;

    // if card supports cardinal authentication navigate to cardinal route
    if (supportsCardinalAuthentication || supportThreeDsAuthentication) {
      history.push({
        pathname: PAYMENT__CARD_CARDINAL,
        state: { cardinalAuthenticationInformation },
      });
      return;
    }

    // if card supports pin redirect to pin page
    if (supportsPin) {
      history.push(PAYMENT__CARD_PIN);
      return;
    }

    const { secure, pinBlock } = generateSecureData(
      cardPanValue,
      expValue,
      cvvValue
    );

    const { merchantCode, payableCode, paymentId } = paymentParams;

    makePayment({
      merchantCode,
      payableCode,
      paymentId,
      secureData: secure,
      pinBlock,
      deviceInformation: getCardinalDeviceInformation(),
    });
  }, [fetchingBinConfiguration]);

  useEffect(() => {
    if (!(!makePaymentPending && prevMakePaymentPending)) return;

    if (makePaymentError) {
      const responseData = getAxiosErrorData(makePaymentError);

      if (!responseData) {
        triggerShowNotification({ type: "ERROR" });
        return;
      }

      const { responseCode } = responseData;

      if (PaymentUtil.isTransactionComplete(responseCode)) {
        completeTransaction(responseData);
        return;
      }

      triggerShowNotification({ type: "ERROR" });
      return;
    }

    if (!makePaymentResponse) return;

    const { responseCode } = makePaymentResponse;

    if (PaymentUtil.isTransactionComplete(responseCode)) {
      completeTransaction(makePaymentResponse);
      return;
    }

    showNotification({ type: "ERROR" });
  }, [makePaymentPending]);

  let paymentProps = window.iswPaymentProps;
  const { acquiredBy } = paymentProps;

  return (
    <Container>
      <BackControl
        text={
          currentPaymentChannel === "WALLET"
            ? "Select card"
            : "Change payment method"
        }
        onClick={goBack}
      />

      <PageTitle>Pay with card</PageTitle>
      <PageSubTitle>Enter your card details below</PageSubTitle>

      <Form onSubmit={formSubmitHandler}>
        <LabelFieldContainer>
          <FieldLabel htmlFor="card-number">Card Number</FieldLabel>

          <CardInputField
            id="card-number"
            initialValue={cardPanValue}
            onValueChange={(value) => setCardPanValue(value)}
            error={showFormErrors && !isCardNumberValid}
          />

          {showFormErrors && !isCardNumberValid && (
            <FieldErrorText>{cardNumberErrorMessage}</FieldErrorText>
          )}
        </LabelFieldContainer>

        <FormFieldsRow>
          <LabelFieldContainer>
            <FieldLabel htmlFor="card-exp">Expiry</FieldLabel>

            <ExpiryInputField
              id="card-exp"
              initialValue={expValue}
              onValueChange={(value) => setExpValue(value)}
              error={showFormErrors && !isCardExpValid}
            />

            {showFormErrors && !isCardExpValid && (
              <FieldErrorText>{cardExpErrorMessage}</FieldErrorText>
            )}
          </LabelFieldContainer>

          <LabelFieldContainer>
            <FieldLabel htmlFor="card-cvv">CVV</FieldLabel>

            <CvvInputField
              id="card-cvv"
              value={cvvValue}
              onValueChange={(value) => setCvvValue(value)}
              error={showFormErrors && !isCardCvvValid}
            />

            {showFormErrors && !isCardCvvValid && (
              <FieldErrorText>{cardCvvErrorMessage}</FieldErrorText>
            )}
          </LabelFieldContainer>
        </FormFieldsRow>

        {acquiredBy === "ZIB" && (
          <DisclaimerContainer>
            <DisclaimerView />
          </DisclaimerContainer>
        )}

        <Button
          text={`Pay ${getFormattedPrice(
            paymentParams.amount,
            paymentParams.currencyCode
          )}`}
          color={acquiredBy === "ZIB" ? "DANGER" : "PRIMARY"}
          loading={fetchingBinConfiguration || makePaymentPending}
          onClick={handleSubmit}
          containerStyle={{ width: "100%" }}
        />
      </Form>
    </Container>
  );
}

const mapStateToProps = (state: AppState): StoreStateProps => ({
  paymentParams: state.payment.paymentProperties.paymentParams as PaymentParams,
  currentPaymentChannel: state.payment
    .currentPaymentChannel as PaymentChannelType,
  cachedCardParams: state.card.cachedCardDetails.cachedCardParams,

  fetchingBinConfiguration:
    state.card.getBinConfiguration.fetchingBinConfiguration,
  fetchingBinConfigurationError:
    state.card.getBinConfiguration.fetchingBinConfigurationError,
  fetchingBinConfigurationErrorMessage:
    state.card.getBinConfiguration.errorMessage,
  binConfigurationResponse: state.card.getBinConfiguration
    .binConfigurationResponse as GetBinConfigurationResponse,

  makePaymentPending: state.card.makePayment.makePaymentPending,
  makePaymentError: state.card.makePayment.makePaymentError,
  makePaymentResponse: state.card.makePayment.makePaymentResponse,
});

const mapDispatchToProps = (
  dispatch: (action: any) => void
): StoreDispatchProps => ({
  showNotification(payload: TriggerShowNotificationPayload) {
    dispatch(triggerShowNotification(payload));
  },
  completeTransaction(transactionResponse: any) {
    dispatch(completeTransaction(transactionResponse));
  },
  setCachedCardDetails(cardParams: CachedCardParams) {
    dispatch(setCachedCardDetails(cardParams));
  },
  getBinConfiguration(request: GetBinConfigurationRequest) {
    dispatch(triggerGetBinConfiguration(request));
  },
  makePayment(request: MakePaymentRequest) {
    dispatch(triggerMakePayment(request));
  },

  resetCachedCardDetails() {
    dispatch(resetCachedCardDetails());
  },
  resetGetBinConfiguration() {
    dispatch(resetGetBinConfiguration());
  },
  resetMakePayment() {
    dispatch(resetMakePayment());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CardRootPage);
