import React, { useEffect } from "react";
import styled, { css } from "styled-components";
import { ColorTheme } from "../../../styling/theme";

import { ReactComponent as BackIcon } from "./back-icon.svg";
import { PAGE_SIDE_PADDING } from "../../../styling/constants";

interface ContainerProps {
  padded: boolean;
}

const Container = styled.div<ContainerProps>`
  height: 40px;
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 10px;
  margin-bottom: 30px;
  background-color: #f5f9fb;
  border-radius: 5px;
  cursor: pointer;
  user-select: none;

  ${(props) => props.padded && css`
    margin-top: 20px;
    margin-left: ${PAGE_SIDE_PADDING}px;
  `}

  svg {
    margin-right: 10px;
  }

  p {
    font-size: 1.2rem;
    color: ${ColorTheme.primaryColor};
  }
`;

interface Props {
  text: string;
  padded?: boolean;
  onClick: () => void;
}

export default function BackControl(props: Props) {
  const { text, padded = false, onClick } = props;

  return (
    <Container onClick={onClick} padded={padded}>
      <BackIcon />
      <p>{text}</p>
    </Container>
  );
}
