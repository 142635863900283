import * as React from "react";
import { useDispatch, useSelector } from "react-redux";

import { AppState } from "../../../../../../store/RootReducer";
import { PaymentParams } from "../../../../../payment/presentation/store/paymentProperties/types";
import { triggerGenerateAccount } from "../../../store/generateAccount/actions";

export default function useGenerateBankAccount(paymentParams: PaymentParams) {
  const dispatch = useDispatch();

  const { loading, accountNumber, error } = useSelector((state: AppState) => ({
    loading: state.transfer.generateAccount.generateAccountPending,
    accountNumber: state.transfer.generateAccount.generateAccountResponse,
    error: state.transfer.generateAccount.generateAccountError,
  }));

  const generateAccountNumber = React.useCallback(() => {
    dispatch(
      triggerGenerateAccount({
        amount: paymentParams.amount,
        currencyCode: paymentParams.currencyCode,
        merchantCode: paymentParams.merchantCode,
        merchantTransactionReference: paymentParams.merchantTransactionReference,
        payableCode: paymentParams.payableCode,
        paymentId: paymentParams.paymentId,
        surcharge: paymentParams.surcharge,
      })
    );
  }, [dispatch, paymentParams]);

  React.useEffect(() => {
    generateAccountNumber();
  }, [generateAccountNumber]);

  return { generateAccountNumber, loading, accountNumber, error };
}
