import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  PAYMENT__CARD_ROOT,
  PAYMENT__WALLET_LOGIN,
  PAYMENT__TRANSFER_ROOT,
  PAYMENT__QR_ROOT,
  PAYMENT__USSD_ROOT,
  PAYMENT__CUSTOMER_WALLET_ROOT,
  PAYMENT__CREDIT,
  PAYMENT__GOOGLE_PAY,
  PAYMENT__PAY_WITH_APP,
  PAYMENT__UGANDA_MOBILE_MONEY,
  PAYMENT__OPAY_ROOT,
} from "../../../../Routes";

import MethodItem from "../../../../components/MethodItem";

import { connect, useDispatch } from "react-redux";
import { AppState } from "../../../../../store/RootReducer";
import {
  WalletPaymentMethod,
  WalletUserData,
} from "../../../../wallet/domain/repositories/WalletRepository";

import { Container } from "./style";

import {
  NonCardOption,
  PaymentChannelType,
  PaymentParams,
  SET_ESCROW_STATUS,
  SET_TUC_STATUS,
} from "../../store/paymentProperties/types";
import EscrowPopup from "./components/EscrowPopup";
import TUCPopup from "./components/TUCPopup";
import { ReactComponent as CardIcon } from "./icons/card-icon.svg";
import { ReactComponent as CustomerWalletIcon } from "./icons/customer-wallet-icon.svg";
import { ReactComponent as GoogleIcon } from "./icons/google-pay-mark_800.svg";
import { ReactComponent as QrIcon } from "./icons/qr-icon.svg";
import { ReactComponent as TransferIcon } from "./icons/transfer-icon.svg";
import { ReactComponent as UssdIcon } from "./icons/ussd-icon.svg";
import { ReactComponent as WalletIcon } from "./icons/wallet-icon.svg";
import { ReactComponent as MobileMoneyIcon } from "./icons/mobile-money.svg";
// import { ReactComponent as PayWithAppIcon } from "./icons/pay-with-app.svg";
import OPayIconPath from "./icons/opay.svg";
import { setPaymentChannel } from "../../store/currentPaymentChannel/actions";

interface PaymentChannelProps {
  type: PaymentChannelType;
  title: string;
  imageIcon: React.ReactNode;
  description?: string;
  plainIcon?: boolean;
}

const path = window.location.hostname;

const paymentChannelsProps: PaymentChannelProps[] = [
  {
    type: "CARD",
    title: "Pay with Card",
    imageIcon: <CardIcon />,
    description: path.includes("zivastores")
      ? "Verve, Visa and Mastercard cards are all accepted "
      : "Verve, Visa, Mastercard, discover and Amex cards are all accepted ",
  },
  {
    type: "WALLET",
    title: "Pay with Quickteller",
    imageIcon: <WalletIcon />,
    description:
      "Login to your quickteller wallet to get access to your saved cards.",
  },
  {
    type: "OPAY",
    title: "Pay With Opay",
    imageIcon: <TransferIcon />,
    description: "Complete trasaction with OPay",
  },
  {
    type: "TRANSFER",
    title: "Pay with Bank Transfer",
    imageIcon: <TransferIcon />,
    description:
      "Make a transfer directly from your bank account to complete a transaction",
  },
  {
    type: "QR",
    title: "Pay with QR",
    imageIcon: <QrIcon />,
    description: "Generate a QR code you can scan with your bank app to pay.",
  },
  {
    type: "USSD",
    title: "Pay with USSD",
    imageIcon: <UssdIcon />,
    description:
      "Dial a USSD string from any of 17+ banks to complete a transaction",
  },
  // {
  //   type: "PAY_WITH_APP",
  //   title: "Pay with app",
  //   imageIcon: <PayWithAppIcon />,
  //   description:
  //     "Seamlessly make payments from your bank's mobile banking apps, and also your non-bank payment apps",
  // },
  {
    type: "CUSTOMER_WALLET",
    title: "Pay with Wallet",
    imageIcon: <CustomerWalletIcon />,
    description: "Make secure payments using third-party payment solutions.",
  },
  {
    type: "GOOGLE_PAY",
    title: "Google Pay",
    imageIcon: <GoogleIcon />,
    description:
      "Make secure payments using your instruments saved with Google.",
    plainIcon: true,
  },
  {
    type: "CRD",
    title: "Pay on Credit",
    imageIcon: <CustomerWalletIcon />,
    description: "Buy whatever you need today and pay later",
  },
  {
    type: "UGANDA_MOBILE_MONEY",
    title: "Pay with Mobile Money",
    imageIcon: <MobileMoneyIcon />,
    description: "Use your Mobile Money credentials to make secure payments",
  },
];

type EnabledChannelsMap = {
  [key in PaymentChannelType]: boolean;
};

const defaultEnabledChannelsMap = {
  CARD: true,
  WALLET: false,
  TRANSFER: false,
  OPAY: false,
  QR: false,
  USSD: false,
  PAY_WITH_APP: false,
  CUSTOMER_WALLET: false,
  CRD: false,
  GOOGLE_PAY: false,
  UGANDA_MOBILE_MONEY: false,
};

interface StoreStateProps {
  paymentChannels: NonCardOption[];
  paymentParams: PaymentParams;
  userData: WalletUserData | null;
  walletCards: WalletPaymentMethod[] | null;
  tucAccepted?: boolean;
  escrowAccepted?: boolean;
}

interface StoreDispatchProps {
  setPaymentChannel: (channelType: PaymentChannelType) => void;
}

interface OwnProps {}

type Props = StoreStateProps & StoreDispatchProps & OwnProps;

export function SelectPaymentMethodPage(props: Props) {
  const {
    paymentChannels,
    paymentParams,
    tucAccepted,
    escrowAccepted,
    setPaymentChannel,
  } = props;
  const isUgandan = paymentParams.currencyCode === "800";

  const history = useHistory();

  const [enabledChannelsMap, setEnabledChannelsMap] =
    useState<EnabledChannelsMap>(defaultEnabledChannelsMap);

  const onMethodSelect = useCallback(
    (channelType: PaymentChannelType) => {
      setPaymentChannel(channelType);

      if (channelType === "PAY_WITH_APP") {
        history.push(PAYMENT__PAY_WITH_APP);
      }

      if (channelType === "CARD") {
        history.push(PAYMENT__CARD_ROOT);
      }

      if (channelType === "WALLET") {
        history.push(PAYMENT__WALLET_LOGIN);
      }

      if (channelType === "TRANSFER") {
        history.push(PAYMENT__TRANSFER_ROOT);
      }

      if (channelType === "OPAY") {
        history.push(PAYMENT__OPAY_ROOT);
      }

      if (channelType === "QR") {
        history.push(PAYMENT__QR_ROOT);
      }

      if (channelType === "USSD") {
        history.push(PAYMENT__USSD_ROOT);
      }

      if (channelType === "CUSTOMER_WALLET") {
        history.push(PAYMENT__CUSTOMER_WALLET_ROOT);
      }

      if (channelType === "CRD") {
        history.push(PAYMENT__CREDIT);
      }

      if (channelType === "GOOGLE_PAY") {
        history.push(PAYMENT__GOOGLE_PAY);
      }
      if (channelType === "UGANDA_MOBILE_MONEY") {
        history.push(PAYMENT__UGANDA_MOBILE_MONEY);
      }
    },
    [setPaymentChannel, history]
  );

  let paymentProps = window.iswPaymentProps;
  const { acquiredBy } = paymentProps;

  useEffect(() => {
    setEnabledChannelsMap(() => {
      const newEnabledChannelsMap = {
        ...defaultEnabledChannelsMap,
      };

      paymentChannels.forEach((channel) => {
        const { providerCode, enabled } = channel;
        newEnabledChannelsMap[providerCode] = enabled;

        if (!isUgandan && providerCode === "UGANDA_MOBILE_MONEY") {
          newEnabledChannelsMap["UGANDA_MOBILE_MONEY"] = false;
        }

        if (isUgandan) {
          if (
            providerCode !== "CARD" &&
            providerCode !== "UGANDA_MOBILE_MONEY"
          ) {
            newEnabledChannelsMap[providerCode] = false;
          }

          return newEnabledChannelsMap;
        }

        if (providerCode === "CARD") {
          newEnabledChannelsMap["CARD"] = enabled;
          newEnabledChannelsMap["WALLET"] = enabled;
        }

        if (providerCode === "CUSTOMER_WALLET") {
          newEnabledChannelsMap["OPAY"] = enabled;
        }
      });

      if (acquiredBy === "ZIB") {
        newEnabledChannelsMap["CARD"] = true;
        newEnabledChannelsMap["TRANSFER"] = true;
        newEnabledChannelsMap["WALLET"] = false;
        newEnabledChannelsMap["CUSTOMER_WALLET"] = false;
        newEnabledChannelsMap["QR"] = false;
        newEnabledChannelsMap["USSD"] = false;
        newEnabledChannelsMap["CRD"] = false;
        newEnabledChannelsMap["GOOGLE_PAY"] = false;
        newEnabledChannelsMap["UGANDA_MOBILE_MONEY"] = false;
        newEnabledChannelsMap["OPAY"] = false;
      } else if (acquiredBy === "HALO") {
        newEnabledChannelsMap["CARD"] = true;
        newEnabledChannelsMap["TRANSFER"] = true;
        newEnabledChannelsMap["WALLET"] = true;
        newEnabledChannelsMap["QR"] = true;
        newEnabledChannelsMap["USSD"] = true;
        newEnabledChannelsMap["CRD"] = true;
        newEnabledChannelsMap["GOOGLE_PAY"] = true;
      }

      return newEnabledChannelsMap;
    });
  }, [paymentChannels, acquiredBy, isUgandan]);

  const hasTUCEnabled = React.useMemo(
    () =>
      !!(
        paymentParams.tucRewardPercentage && paymentParams.tucRewardDescription
      ),
    [paymentParams.tucRewardPercentage, paymentParams.tucRewardDescription]
  );

  // useEffect(() => {
  //   const enabledChannels = Object.keys(enabledChannelsMap).filter(
  //     (channel) => !!enabledChannelsMap[channel as PaymentChannelType]
  //   );
  //   const acquiredBy = sessionStorage.getItem("acquiredBy");
  //   if (enabledChannels.length === 1 && !hasTUCEnabled && acquiredBy !== "ZIB") {
  //     onMethodSelect(enabledChannels[0] as PaymentChannelType);
  //   }
  // }, [enabledChannelsMap, paymentChannels, onMethodSelect, hasTUCEnabled]);

  const dispatch = useDispatch();

  const onTUCDone = useCallback(() => {
    dispatch({
      type: SET_TUC_STATUS,
      payload: true,
    });
  }, [dispatch]);

  const onEscrowDone = useCallback(() => {
    dispatch({
      type: SET_ESCROW_STATUS,
      payload: true,
    });
  }, [dispatch]);

  const hasEscrowEnabled =
    !!paymentParams &&
    !!(
      paymentParams.escrowFee &&
      paymentParams.cancellationPeriod &&
      paymentParams.refundDuration
    ) &&
    !escrowAccepted;

  return (
    <Container>
      {hasTUCEnabled && !tucAccepted && (
        <TUCPopup onDone={onTUCDone} paymentParams={paymentParams} />
      )}

      {hasEscrowEnabled && (
        <EscrowPopup onDone={onEscrowDone} paymentParams={paymentParams} />
      )}

      {paymentChannelsProps.map((props) => {
        const { type, title, imageIcon, description, plainIcon } = props;

        if (!enabledChannelsMap[type]) return null;

        // const acquiredBy = sessionStorage.getItem("acquiredBy");
        // if (acquiredBy === "ZIB" && type !== "CARD") return null;

        return (
          <MethodItem
            key={type}
            type={type}
            title={title}
            description={description}
            imageIcon={type !== "OPAY" ? imageIcon : undefined}
            iconPath={type === "OPAY" ? OPayIconPath : undefined}
            plainIcon={plainIcon}
            onSelect={() => onMethodSelect(type)}
          />
        );

        // return (
        //   <MethodContainer
        //     data-testid={type}
        //     key={type}
        //     onClick={() => onMethodSelect(type)}
        //   >
        //     <IconContainer className={plainIcon ? "plain" : undefined}>
        //       {imageIcon}
        //     </IconContainer>

        //     <DetailsContainer>
        //       <h6>{title}</h6>
        //       <p>{description}</p>
        //     </DetailsContainer>

        //     <ArrowIconContainer>
        //       <RightArrowIcon />
        //     </ArrowIconContainer>
        //   </MethodContainer>
        // );
      })}
    </Container>
  );
}

const mapStateToProps = (state: AppState): StoreStateProps => ({
  paymentChannels: state.payment.paymentProperties
    .paymentChannels as NonCardOption[],
  paymentParams: state.payment.paymentProperties.paymentParams as PaymentParams,
  tucAccepted: state.payment.paymentProperties.tucAccepted,
  escrowAccepted: state.payment.paymentProperties.escrowAccepted,

  userData: state.wallet.userWalletData.userData,
  walletCards: state.wallet.userWalletData.walletCards,
});

const mapDispatchToProps = (
  dispatch: (action: any) => void
): StoreDispatchProps => ({
  setPaymentChannel(channelType: PaymentChannelType) {
    dispatch(setPaymentChannel(channelType));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectPaymentMethodPage);
