export const TRIGGER_INITIALIZE =
  "PAYMENT.PAYMENT_PROPERTIES.TRIGGER_INITIALIZE";
export const INITIALIZE_COMPLETE =
  "PAYMENT.PAYMENT_PROPERTIES.INITIALIZE_COMPLETE";

export const SET_ACQUIRED_BY = "PAYMENT.PAYMENT_PROPERTIES.SET_ACQUIRED_BY";

export const SET_TUC_STATUS = "PAYMENT.PAYMENT_PROPERTIES.SET_TUC_STATUS";
export const SET_ESCROW_STATUS = "PAYMENT.PAYMENT_PROPERTIES.SET_ESCROW_STATUS";

export type AppDisplayMode = "REDIRECT" | "INLINE";

export type PaymentChannelType =
  | "CARD"
  | "WALLET"
  | "TRANSFER"
  | "OPAY"
  | "QR"
  | "USSD"
  | "CUSTOMER_WALLET"
  | "CRD"
  | "GOOGLE_PAY"
  | "PAY_WITH_APP"
  | "UGANDA_MOBILE_MONEY";

export interface NonCardOptionAdditionalInfo {
  providers: {
    id: number;
    code: string;
    description: string;
    countryCode: string;
  }[];
}
export interface NonCardOption {
  additionalInformation: NonCardOptionAdditionalInfo | null;
  enabled: boolean;
  payableCode: string;
  providerCode: PaymentChannelType;
}

export interface PaymentParams {
  accessToken?: string;
  accessTokenType?: string;
  amount: number;
  baseUrl: string;
  consentUrl: string;
  currencyCode: string;
  customerId: string;
  customerName: string;
  customerEmail?: string;
  customerMobileNo?: string;
  cybersourceMerchantId: string;
  cybersourceOrgId: string;
  logoUrl: string;
  merchantCode: string;
  merchantName: string;
  merchantTransactionReference: string;
  nonCardOptions: NonCardOption[];
  payableCode: string;
  payableId: number;
  paymentId: number;
  surcharge: number;
  transactionDate: number;
  transactionType: string;
  virtualProductNetworkId: string;
  siteRedirectUrl: string;
  siteOrigin: string;
  tucRewardPercentage?: string;
  tucRewardDescription?: string;
  cancellationPeriod?: string;
  refundDuration?: string;
  escrowFee?: string;
}

export interface TriggerInitialize {
  type: typeof TRIGGER_INITIALIZE;
  payload: {
    paymentProperties: any;
  };
}

export interface InitializeCompletePayload {
  displayMode: AppDisplayMode;
  paymentParams: PaymentParams;
  paymentChannels: NonCardOption[];
}

export interface InitializeComplete {
  type: typeof INITIALIZE_COMPLETE;
  payload: InitializeCompletePayload;
}

export interface SetTUCStatus {
  type: typeof SET_TUC_STATUS;
  payload: boolean;
}

export interface SetEscrowStatus {
  type: typeof SET_ESCROW_STATUS;
  payload: boolean;
}

export type PaymentPropertiesActionType =
  | TriggerInitialize
  | InitializeComplete
  | SetTUCStatus
  | SetEscrowStatus;

export interface PaymentPropertiesState {
  initialized: boolean;
  displayMode: AppDisplayMode;
  paymentParams: PaymentParams | null;
  paymentChannels: NonCardOption[] | null;
  tucAccepted?: boolean;
  escrowAccepted?: boolean;
}
